<!-- eslint-disable no-undef -->
<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="d-lg-flex justify-content-between align-items-center px-2 py-1">
          <h3 class="font-weight-bolder">
            {{ $t('couponList') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="filter"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <router-link
          :to="{name: 'admin-coupon-create'}"
          class="px-2"
        >
          <button
            type="button"
            class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
          >
            <span>+ {{ $t('addCoupon') }} </span>
          </button>
        </router-link>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(number)="data">
                <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                  COUP-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
                </b-link>
              </template>

              <template #cell(discount)="data">
                {{ data.item.discount }} บาท
              </template>

              <template #cell(groupuser)="data">
                {{ data.item.groupuser.length }} {{ $t('key-74') }}
              </template>
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(active)="data">
                <b-form-checkbox
                  v-model="data.item.active"
                  name="is_active"
                  switch
                  inline
                  :value="1"
                  :unchecked-value="0"
                  @change="CheckUpdate(data.item.active, 'active',data.item._id)"
                />
              </template>

              <template #cell(action)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />

                <feather-icon
                  icon="TrashIcon"
                  class="text-danger cursor-pointer"
                  @click="DeleteData(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink, BPagination, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BTable,
    BLink,
    BPagination,
    BFormCheckbox,
    BOverlay,
  },
  data() {
    return {

      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      totalRow: 0,
      filter: null,
      items: [],
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'name', label: this.$t('couponName'), sortable: false, thStyle: { width: '40%' }, thClass: 'text-center',
        },
        {
          key: 'discount', label: this.$t('discount'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'groupuser', label: this.$t('userCount'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'expdate',
          label: this.$t('expiryDate'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: value => moment(value).tz('Asia/Bangkok').format('DD/MM/YYYY'),
        },
        {
          key: 'active', label: this.$t('usageStatus'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    async getData(type) {
      this.showOver = true
      this.isActive = type
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/coupon/coupon_showall', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    EditType(data) {
      this.$router.push({
        // eslint-disable-next-line no-underscore-dangle
        name: 'admin-coupon-edit', params: { id: data._id },
      })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            }
            this.$http.post('/coupon/coupon_delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    CheckUpdate(data, fields, ID) {
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        }
        this.$http.post('/coupon/coupon_update_field', obj)
      }
    },
  },
}
</script>

                <style lang="scss" scoped></style>
